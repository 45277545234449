import React from "react";

export default function AboutUs() {
  return (
    <>
      <div className="about container-fluid ">
        <div className="container d-flex flex-column justify-contgent-center">
          <div
            className="row  mx-auto center-div bg-image"
            style={{ backgroundImage: `url(/Images/9.jpg)` }}
          >
            <div className="col-lg-4 mx-auto row">
              <div className="col-3">
                <h1>
                  <b>01</b>
                </h1>
              </div>
              <div className="col-9">
                <h4>
                  <b>Development of marketing strategies</b>
                </h4>
              </div>
              <div className="col-12">
                <p className="justify">
                  We will help you develop an appropriate strategy for your
                  business to achieve your objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto row">
              <div className="col-3">
                <h1>
                  <b>02</b>
                </h1>
              </div>
              <div className="col-9">
                <h4>
                  <b>Digital Marketing</b>
                </h4>
              </div>
              <div className="col-12">
                <p className="justify">
                  We offer a full range of digitalmarketing services, including
                  SEO, content marketing, social media among other services
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto row">
              <div className="col-3">
                <h1>
                  <b>03</b>
                </h1>
              </div>
              <div className="col-9">
                <h4>
                  <b>Advertising And PR</b>
                </h4>
              </div>
              <div className="col-12">
                <p className="justify">
                  Our advertising and PR experts will help you make your brand
                  visible and attractive to your target audience.
                </p>
              </div>
            </div>
          </div>

          <div id="about" className="row">
            <div className=" col-md-4">
                <div style={{height:'auto'}} className="col-12 dashed-border">

              <div className="w-100 about-us-img-wrapper">

                <img className="about-us-img" src="/Images/11.png" />
                </div>
              </div>
            </div>

            <div className="col-md-8 row">
              <p>
                <b>About us</b>
              </p>
              <h1>We love what we do</h1>
              <p
                className="justify"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                OSIRIS is made up of graphic designers and creatives, web
                programmers and developers, and specialized writers in both
                online and offline strategies. They are all dedicated to
                offering personalized and high-quality service to each client,
                working every day to create brand value and build unique
                experiences through their work.
              </p>
              <div className="col-lg-6 mt-2">
                <div className="col-12 zoom-div invert-shadow-div px-4 py-4">
                  <h4>The company's vision</h4>
                  <p className="justify">
                    is to become a leader in the digital marketing market,
                    providing services in different countries and markets, and
                    adapting to the needs and particularities of each one. To
                    achieve this, the company strives to stay updated with the
                    latest trends and technologies in the market, always with
                    the goal of offering the best results to its clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 mt-2">
                <div className="col-12 zoom-div invert-shadow-div px-4 py-4">
                  <h4>Our values</h4>
                  <p className="justify">
                    include creativity, innovation, collaboration, and
                    excellence in customer service. From our team, we believe
                    that creativity and innovation are fundamental to achieving
                    unique and effective solutions for each client. We also
                    believe in collaboration and close communication with our
                    clients to achieve the best results.
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
