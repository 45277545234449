import React from "react";
import LegalPageNavbar from "../Components/LegalPageNavbar";
import MetaData from "../Components/MetaData";

export default function LegalNotice() {
  return (
    <>
    <MetaData title="Legal Notice"/>
      <LegalPageNavbar />
      <div
        style={{
          backgroundImage: `url(/Images/FhpeXInXEAEvFME.jpg)`,
        }}
        className="container rounded-5 shadow cookies bg-image-top-container justify-content-center d-flex align-items-center flex-wrap"
      >
        <h1 className="primary-color cookie-heading">
          LEGAL NOTICE OF OSIRIS INTERNATIONAL LLC
        </h1>
        <div
          className="p-3 dashed-border"
          style={{
            minHeight: "100vh",
            width: "50%",
            position: "absolute",
            left: "-20px",
          }}
        ></div>
      </div>
      <div className="container py-5 cookie-content">
        <p>
          Welcome to OSIRIS INTERNATIONAL LLC, a Dubai-based company that
          specializes in advising, consulting, and developing visual identity
          and digital marketing projects. By using our services and website, you
          agree to the following terms and conditions of use. If you do not
          agree to these terms, you may not use our services.
        </p>

        <h2>Company Information</h2>
        <p>
          OSIRIS INTERNATIONAL LLC is registered with the Department of Economic
          Development of Dubai (DED) and has the necessary permits and licenses
          to operate under license number 2011218.01. Our registered office is
          located at Sharjah Media City, Sharjah, UAE, Dubai, United Arab
          Emirates.
        </p>

        <h2>Intellectual Property</h2>
        <ol type="a">
          <li>
            All intellectual property rights related to our website and
            services, including but not limited to copyright, trademarks, and
            patents, are the exclusive property of OSIRIS INTERNATIONAL LLC and
            are protected by applicable intellectual property laws.
          </li>
          <li>
            Reproduction, distribution, or modification of any content on our
            website or services without our prior written consent is not
            allowed.
          </li>
        </ol>

        <h2>Limitation of Liability</h2>
        <ol type="a">
          <li>
            We will not be liable for any loss or damage arising from the use of
            our website or services, including but not limited to any loss of
            profits, loss of data, loss of business, or interruption of business
            activity.
          </li>
          <li>
            We strive to provide accurate and up-to-date information on our
            website and services, but we do not guarantee the accuracy,
            completeness, or timeliness of such information.
          </li>
          <li>
            We reserve the right to modify or remove any content or service on
            our website without notice.
          </li>
        </ol>

        <h2>Links to Third-Party Websites</h2>
        <p>
          Our website may contain links to third-party websites that are not
          under our control. We are not responsible for the content or privacy
          practices of these websites.
        </p>

        <h2>Applicable Law and Jurisdiction</h2>
        <p>
          These terms and conditions shall be governed and interpreted in
          accordance with the laws of the United Arab Emirates, and any dispute
          arising in connection with these terms and conditions shall be subject
          to the exclusive jurisdiction of the courts of Dubai.
        </p>

        <p>
          If you have any questions or concerns about our terms and conditions,
          please feel free to contact us at{" "}
          <a href="mailto:info@osirismarketing.io">
            <b>info@osirismarketing.io</b>
          </a>
        </p>
      </div>
    </>
  );
}
