import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem("cookiesAccepted");
    if (!accepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };
  const handleCancel = () => {
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <div className="container">
          <div className="row d-flex flex-wrap ">
            <div className="col-md-9">
              <p>
                We use cookies to improve your browsing experience. By
                continuing to use our site, you agree to our <Link to="/cookie-policy">cookies policy.</Link>
              </p>

          </div>
          <div className="col-md-3 d-flex justify-content-center">
            <button className="btn mx-1 my-1 btn-danger" onClick={handleCancel}>Cancel</button>
            <button className="btn mx-1 my-1 btn-primary" onClick={handleAccept}>Accept</button>
          </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
