import React from "react";
import TestimonialSlider from "./TestimonialSlider";
const testimonials = [
    {
      message: "Amazing product! It has changed my life significantly for the better. I can't believe how much more productive and organized I am now. The user interface is intuitive, and the features are incredibly useful. I highly recommend this product to anyone looking to improve their workflow.",
      name: 'John Doe',
      position: 'CEO, Company Inc.',
    },
    {
      message: "I never thought I would find something so useful and easy to use. This software has streamlined our processes and improved collaboration within our team. The built-in analytics and reporting have been invaluable in helping us make data-driven decisions. We have seen substantial growth since implementing this tool.",
      name: 'Jane Smith',
      position: 'CTO, Another Corp.',
    },
    {
      message: "The support team is fantastic, always ready to help. They have been responsive to our questions and requests, and their guidance has been instrumental in our success. The frequent updates and improvements show that they genuinely care about their clients and the quality of their product. We couldn't be happier with our choice.",
      name: 'Michael Brown',
      position: 'COO, Tech Solutions Ltd.',
    },
  ];
export default function JoinUs() {
  return (
    <>
      <div className="container-fluid py-5 bg-light">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 ">
              <div className="col-12">
                <h2>
                  <b className="primary-color">
                    Join up a digital marketing agency that creates growth
                  </b>
                </h2>
                <p className="black-p justify">
                  <b>We do simple, useful and beautiful solutions.</b> Let us
                  know what your needs are. Our solutions are the result of
                  specialization in sectors such as real state,{" "}
                  <b>industry and construction, technology and education.</b>
                  <br />
                  We design <b>integral solutions</b> that respond to the needs
                  of our clients, focusing each project from the combination of{" "}
                  <b>sales analysis, predictive analysis</b> including
                  macroeconomic trends, <b>knowledge of the target audience</b>{" "}
                  and using the <b>best technology</b>, thus providing the
                  entire value chain.
                  <br />
                  <b>The success </b>of the <b>business strategy</b> is{" "}
                  <b>based on decision making</b>, so we take advantage of our
                  personal experience thanks to the history of other companies
                  that have trusted us and whose objectives have been fulfilled.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-12">
                <div className="w-100 text-center pt-4 pb-5">
                  <img src="Images/3x/logo-purple.png" className="join-us-img" />
                </div>
                <p className="black-p justify">
                  Our commitment is to maintain a{" "}
                  <b>close relationship with our clients</b> , taking care of
                  every detail, offering satisfactory results and exceeding
                  their expectations. We adapt to the economic sector each
                  company represents, adjusting in the most efficient and
                  adequate way to the real needs and aspirations they have for
                  their future,
                  <b>
                    maximizing the return on investment from the very first
                    moment.
                  </b>
                </p>
              </div>
            </div>

            <div className="col-12 my-4 text-center">
              <a href="#contact">
                {" "}
                <button className="btn btn-talk rounded-5">
                  Talk to Our Expert
                </button>
              </a>
            </div>
            <div className="col-8 mx-auto mb-5 join-us-line"></div>

            <div className="col-12">
              <TestimonialSlider  testimonials={testimonials}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
