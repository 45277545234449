import React from "react";
import { Link } from "react-router-dom";

export default function ServicesDiv(props) {
  return (
    <>
      <div className="col-lg-4 col-md-6 mt-1 mb-5 " key={props.key}>
        <div className="col-11  p-3  dashed-border">
          <Link to={props.link}>
          <div className="bordered-div glow-div px-4 py-3">
            <div className="col-12 text-left my-3"><img src={props.icon} width={80}/></div>
            <h3 style={{fontWeight:'400 !important'}}>{props.title}</h3>
            <p
              className="justify"
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </div>
          </Link>
        </div>
      </div>
    </>
  );
}
