import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    speed: 500,
  });

  useEffect(() => {
    const updateSettings = () => {
      if (window.innerWidth < 786) {
        setSettings({
          ...settings,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else {
        setSettings({
          ...settings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });
      }
    };

    updateSettings();

    window.addEventListener("resize", updateSettings);
    return () => window.removeEventListener("resize", updateSettings);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.elements.fname.value;
    const email = e.target.elements.email.value;
    const message = e.target.elements.message.value;

    // Validate form input
    if (!name || !email || !message) {
      alert("All fields are required");
      return;
    }

    // Add regular expression to validate email format
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!email.match(emailRegex)) {
      alert("Invalid email format");
      return;
    }

    emailjs
      .sendForm(
        "Your_Service_ID",
        "Your_Template_ID",
        e.target,
        "Public_User_ID"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message");
        }
      );
  };

  return (
    <>
      <div className="container-fluid py-3 contact-us-container " id="contact">
        <div className="overlay"></div>
        <div className="container my-5">
          <div className="row contact-us-content">
            <div className="col-lg-6 col-md-12">
              <div className="d-flex flex-column justify-content-between h-100">
                <div>
                  <h1>Start Now on Your Growth Journey</h1>
                  <p>Build your business successfully with us</p>
                </div>
                <div>
                  <h1>Contact Us:</h1>
                  <p>
                    Shams Media City, Sharjah
                    <br /> Dubai, United Arab Emirates
                  </p>
                  <p>info@osirismarketing.io</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <form onSubmit={sendEmail}>
                <div className="form-group row my-2">
                  <div className="col-sm-6">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className="form-control contact-input"
                      required
                      id="firstName"
                      name="fname"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control contact-input"
                      required
                      id="lastName"
                      name="lname"
                    />
                  </div>
                </div>
                <div className="form-group row my-2">
                  <div className="col-sm-6">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control contact-input"
                      required
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="url">URL</label>
                    <input
                      type="url"
                      className="form-control contact-input"
                      required
                      id="url"
                      name="url"
                    />
                  </div>
                </div>
                <div className="form-group my-2">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control contact-input"
                    required
                    id="message"
                    name="message"
                    rows="5"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="py-3 px-5 rounded-3 btn btn-talk"
                >
                  Send a Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
