import React from "react";

export default function BussinesAdvisor() {
  return (
    <>
      <div className="container pb-4" id="bussines-advisor">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="col-11">
              <div className="col-12 p-3 dashed-border">
                <div className="bordered-div gradient-overlay px-4 py-3">
                  <div className="col-12 text-left mt-3  ">
                    <img
                      style={{ height: "80vh" }}
                      className=" about-us-img res-img mt-5"
                      src="/Images/10.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6  mt-5 ">
            <div className="col-10 border-left-dot ps-4  py-2 mx-auto ">
              <p className="justify">
                <small>
                  We offer a variety of services to help businesses achieve
                  their goals and maximize their potential. Our services
                  include:
                </small>
              </p>

                <h4 className="my-1 heading-line-left">Business Consulting:</h4>
                <p className="justify  ps-3">
                  <small>
                    We offer advice and guidance for companies in all aspects of
                    their business, from strategy and planning to implementation
                    and execution. We work with our clients to identify
                    opportunities for improvement and develop customized
                    solutions that enable them to achieve their goals.
                  </small>
                </p>

              <h4 className="my-1 heading-line-left">Financial Analysis:</h4>
              <p className="justify  ps-3">
                <small>
                  We provide financial analysis services to help companies
                  better understand their financial situation and make informed
                  decisions. We offer a variety of services, including
                  profitability analysis, cash management and cash flow
                  analysis.
                </small>
              </p>
              <h4 className="my-1 heading-line-left">Marketing Strategy:</h4>
              <p className="justify  ps-3">
                <small>
                  We develop effective marketing strategies to help companies
                  increase their visibility, enhance their brand image and
                  attract new customers. We work with our clients to develop
                  effective marketing campaigns and advertising solutions that
                  enable them to achieve their business objectives.
                </small>
              </p>
              <h4 className="my-1 heading-line-left heading-line-left">Human Resources Management:</h4>
              <p className="justify  ps-3">
                <small>
                  We provide human resource management services to help
                  companies recruit, retain and motivate employees. We offer a
                  variety of services, including performance appraisals, talent
                  management and training program development.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
