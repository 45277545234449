import React from "react";
import BussinesAdvisor from "./Components/BussinesAdvisor";
import EliminateProblems from "./Components/EliminateProblems";
import Hero from "./Components/Hero";
import MarketAnalysis from "./Components/MarketAnalysis";
import Navbar from "../../Components/Navbar";
import MetaData from "../../Components/MetaData";

export default function Advisor() {
  const Navlinks=[
    {
      link:'#bussines-advisor',
      title:'Business Advisor',
    },{
      link:'#market-analysis',
      title:'Market Analysis',

    }];
  return (
    <>
    <MetaData title="Bussines Advisor and Market Analysis"/>
      <Navbar links={Navlinks} />
      <Hero />
      <div className="svg-bg py-5" style={{zIndex:'-2'}}>
        <BussinesAdvisor />
        <MarketAnalysis/>
      </div>
      <EliminateProblems/>
    </>
  );
}
