import React from "react";
import { Link } from "react-router-dom";
import ServicesDiv from "./ServicesDiv";

const servicesData = [
  {
    link: "/advisor",
    icon: "/Images/3x/4.png",
    title: "Business Advisor",
    text: "<b>We advise and guide companies</b> on the best strategies and ways to approach their potential customers, and we do it through <b>new business model opportunities, enhancing marketing activities and improving customer service efforts and sales</b>, all thanks to the previous analysis of market data, achieving a significant impact on their business.",
  },
  {
    link: "/advisor",
    icon: "/Images/3x/5.png",
    title: "Market Analysis",
    text: "<b>We analyze both the information of your company and the market </b>in which you are, studying the competitors as well as the <b>present trends and the opportunities detected</b>,thus having a clearer and more accurate vision of what are the best options for your company.",
  },
  {
    link: "/design",
    icon: "/Images/3x/6.png",
    title: "Design Thinking",
    text: "<b>We create unique customer </b>experiences while actively affecting the digital marketing environment, helping them to find the most suitable corporate image projects for their company, improving their identity and brand recognition, increasing their visibility,<b> improving their positioning in all digital channels, helping to increase their sales and achieving a sense of identification with their users and followers.</b>",
  },
  {
    link: "/design",
    icon: "/Images/3x/1.png",
    title: "People Empowering",
    text: "We offer a comprehensive service based on <b>dedicated and ad-hoc support, guiding and training our clients </b>in the use of the most appropriate technology and solutions according to their type of business and volume of activity. In addition, our training programs help people <b> in the use of the most appropriate technology and solutions according to their type of business and volume of activity. In addition, our training programs help people.</b>",
  },
  {
    link: "marketing",
    icon: "/Images/3x/2.png",
    title: "Digital Marketing",
    text: "We <b>design and program your website, E-commerce and Marketplace, </b>choosing the technology that best suits your projects, <b>seeking the best impact for your business in terms of user experience.</b> <br><br>We build your <b>presence on social networks </b>taking care of your brand and your content in order to <b>increase your community with the message that best suits your strategy.</b>",
  },
  {
    link: "marketing",
    icon: "/Images/3x/3.png",
    title: "SEO Marketing",
    text: "We are expert in <b>web positioning </b>improving search engine results, <b>increasing the online visibility of our clients.</b><br><br> We combine the <b>SEO strategy with social networks </b>to help positioning in digital channels, <b>, increasing sales quickly and making your company a benchmark in their sector.</b>",
  },
];
export default function Services() {
  return (
    <>
      <div className="container-fluid">
        <div className="container pb-2">
          <div className="row pb-5">
            <div className="col-12">
              <div className="col-md-8">
                <p>
                  <b>SERVICES</b>
                </p>
                <h1>
                  Digital marketing services<br/> Get a solutions to your business
                </h1>
                <p
                  className="justify"
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  Our services are designed to help your business find effective
                  solutions.
                </p>
              </div>
            </div>
            {servicesData.map((service) => (

                <ServicesDiv
                  icon={service.icon}
                  key={service.title}
                  title={service.title}
                  text={service.text}
                  link={service.link}
                />

            ))}
          </div>
        </div>
      </div>
    </>
  );
}
