import React from "react";
import MobileScreen from "./MobileScreen";
export default function DesignThinking() {
  return (
    <div className="container py-5" id="design">
      <div className="row">
        <div className="col-12 text-center">
          <b>
            <h1 className="design-heading-res" style={{ fontSize: "5rem" }}>Design Thinking</h1>
          </b>
          <p className="my-3">
            <b> We create unique customer experiences</b> while actively
            affecting the digital marketing environment, helping them to find
            the most suitable corporate image projects for their company,{" "}
            <b>
              improving their identity and brand recognition, increasing their
              visibility, improving their positioning in all digital channels,
              helping to increase their sales and achieving a sense of
              identification with their users and followers.
            </b>
          </p>
        </div>
      </div>

      <div className="row my-5 w-100 mx-auto justify-content-center">
        <MobileScreen />
      </div>
      {/* <div
          className="w-100 mx-auto   join-us-line"
          style={{
            height: "fit-content ",
            padding: "1.7px",
            backgroundColor: "white ",
          }}
        ></div> */}
    </div>
  );
}
