import React from "react";
import DesignThinking from "./Components/DesignThinking";
import Hero from "./Components/Hero";
import Navbar from "../../Components/Navbar";
import PeopleEmpowering from "./Components/PeopleEmpowering";
import MetaData from "../../Components/MetaData";

export default function Design() {
  const Navlinks=[
{
  link:'#design',
  title:'Design Thinking',
},{
  link:'#people',
  title:'People Empowering',

}
  ];

  return (
    <>
    <MetaData title="Design Thinking and People Empowering"/>
      <Navbar links={Navlinks }/>
      <Hero />
      <div className="svg-gradient-bg" style={{zIndex:1}}>
        <DesignThinking />
        <PeopleEmpowering />
      </div>
    </>
  );
}
