import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = ({ testimonials }) => {
  const sliderRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <div>
        <div className="slider-arrows">
          <button className="arrow arrow-left" onClick={() => sliderRef.current.innerSlider.slickPrev()}>&#10094;</button>
          {dots}
          <button className="arrow arrow-right" onClick={() => sliderRef.current.innerSlider.slickNext()}>&#10095;</button>
        </div>
      </div>
    ),
    customPaging: (i) => <button>{i + 1}</button>,
  };

  return (
    <div className="container my-2">
      <b>
        <h2 className="primary-color mb-5">Our Clients Testimonials</h2>
      </b>
      <div className="testimonial-slider-container container">
        <div className="row d-flex">
          <div className="testimonial-quote primary-color text-end col-md-2">
            &ldquo;
          </div>
          <div className="col-md-10">
            <Slider {...settings} ref={sliderRef}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial-slide col-12">
                  <div className="primary-color">
                    {testimonial.message}

                    <div className="testimonial-client-info mt-1">
                      <span>
                        <b className="primary-color">{testimonial.name}</b>
                      </span>
                      <br />
                      <span className="primary-color">
                        {testimonial.position}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
