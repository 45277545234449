import React from "react";

export default function SEO() {
  return (
    <>
      <div id="seo" className=" container-fluid py-5">
        <div className="container d-flex flex-column justify-contgent-center">
          <div className="row  mx-auto contact-us-div py-4 seo-div">
            <div className="col-11 text-center mx-auto">
              <h1>
                Are you looking to improve your website's visibility and drive
                more traffic to your site?
              </h1>
              <p className="col-sm-9 mx-auto text-center">
                Our SEO experts are here to help you!
              </p>
              <a href="#contact" className="btn btn-contact-us seo-contact-btn">
                <b>Contact Us</b>
              </a>
            </div>
          </div>

          <div className="row my-5 ">
            <div className="col-12">
              <div
                className="col-12 rounded-5 p-5 justify seo-bg-img"
                style={{
                  backgroundImage: "url(Images/8.png)",
                }}
              >
                <div className="row  markiting-row my-4">
                  <h1 className="heading-marketing w-100 text-start">
                    <b>SEO</b>
                  </h1>
                  <h4 className="heading-marketing">
                    (Search Engine Optimization)
                  </h4>

                  <div className="col-lg-4 ms-auto col-md-5 col-sm-10 seo-div-wrapper">
                    <div className="col-12 rounded-5 p-4 justify zoom-div  overlay-div-seo">
                      <div className="">
                        At our company, we understand the importance of having a
                        strong online presence and ranking high in search
                        engines. That's why we offer a range of SEO services
                        designed to help our clients improve their website's
                        visibility and drive more traffic to their site
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row markiting-row my-4">
                  <div className="col-lg-4 ms-auto col-md-5 col-sm-10 seo-div-wrapper">
                    <div className="col-12 rounded-5 p-4  justify zoom-div overlay-div-seo">
                      Our team of SEO experts will work closely with you to
                      develop a customized strategy that is tailored to your
                      unique needs and goals. We use the latest SEO techniques
                      and best practices to optimize your website for search
                      engines, helping you achieve higher rankings and increased
                      online visibility.
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center bottom-over-lay-div align-items-stretch my-4 ">
                  <div className="col-lg-4 col-md-6 col-sm-10 my-2  ">
                    <div
                      className="col-12 mx-auto rounded-5 zoom-div p-4 justify overlay-div-marketing "
                      style={{ height: "100%" }}
                    >
                      Whether you're a small business just starting out or a
                      large enter-prise looking to improve your online
                      visibility, our SEO and digital marketing services can
                      help you achieve your goals.
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 my-2">
                    <div
                      className="col-12 mx-auto rounded-5 zoom-div p-4 overlay-div-marketing justify  "
                      style={{ height: "100%" }}
                    >
                      In addition to our SEO services, we also offer a range of
                      digital marketing services designed to help our clients
                      build their online presence and reach their target
                      audience. Our team of experts can help you create engaging
                      content, build your brand on social media, and drive more
                      traffic to your website through paid advertising and other
                      digital marketing strategies.
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 my-2">
                    <div
                      className="col-12 mx-auto rounded-5 zoom-div p-4 overlay-div-marketing justify  "
                      style={{ height: "100%" }}
                    >
                      <div className="">
                        Our SEO services include keyword research, on-page
                        optimization, off-page optimization, link building, and
                        more. We also provide regular reporting and analysis to
                        track your website's progress and make adjustments to
                        your strategy as needed.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
