import React from "react";
import DigitalMarketing from "./Components/DigitalMarketing";
import Hero from "./Components/Hero";
import Navbar from "../../Components/Navbar";
import SEO from "./Components/SEO";
import MetaData from "../../Components/MetaData";

export default function Marketing() {
  const Navlinks = [
    {
      link: "#digital-marketing",
      title: "Digital Marketing",
    },
    {
      link: "#seo",
      title: "SEO",
    },
  ];
  return (
    <>
      <MetaData title="Digital Marketing and SEO" />
      <Navbar links={Navlinks} />
      <Hero />
      <div className="svg-bg pb-5" style={{ backgroundColor: "#703494" }}>
        <DigitalMarketing />
        <SEO />
      </div>
    </>
  );
}
