import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid px-5 py-3">
        <div className="row">
          <div className="col-sm-6 text-start">
            <a href="https://www.facebook.com" target="_blank">
              <img src="Images/3x/facebook.png" width={35} className="mx-2" />
            </a>
            <a href="https://www.instagram.com" target="_blank">
              <img src="Images/3x/insta.png" width={35} className="mx-2" />
            </a>
            <a href="https://www.linkedin.com" target="_blank">
              {" "}
              <img src="Images/3x/linkedin.png" width={35} className="mx-2" />
            </a>
          </div>
          <div className="col-sm-6 text-end">
            <Link to="/legal-notice" className="primary-color" style={{textDecoration:'none'}}>Legal Notice | </Link> {" "}
            <Link to="/privacy-policy" className="primary-color" style={{textDecoration:'none'}}> Privacy Policy |</Link> {" "}
            <Link to="/cookie-policy" className="primary-color" style={{textDecoration:'none'}}> Cookies Policy</Link>
          </div>
        </div>
      </div>
    </>
  );
}
