import React, { useEffect, useState, useRef } from 'react';
import "../../../mobileframe.css";

export default function (props) {

  const [aspectRatio, setAspectRatio] = useState(null);
  const phoneDivRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const { clientWidth } = phoneDivRef.current;
      const screenWidth = window.innerWidth;

      // setAspectRatio((clientHeight) / (clientHeight*8));
      // console.log(aspectRatio);
     if(clientWidth>720){
         setAspectRatio(0.588235);
    }
    else if(clientWidth>=540){
      setAspectRatio(0.2545);
     }
    else if(clientWidth>=512){
      setAspectRatio(0.2525);
     }
     else if(clientWidth<=396){
      console.log(clientWidth);
      setAspectRatio(0.153158);
      console.log("zzzz");
     }
     else if(clientWidth<=420){
      console.log(clientWidth);
      setAspectRatio(0.173158);
      console.log("zzzz");
     }
     else if(clientWidth<=448){
      console.log(clientWidth);
      setAspectRatio(0.193158);
      console.log("zzzz");
     }

    else if(clientWidth<=460){
      setAspectRatio(0.213158);
      console.log(clientWidth);
      console.log("dsdsds");
    }

     else{
      console.log("kkkkk")
      setAspectRatio(0.223158);
     }
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation of aspect ratio
    handleResize();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const phoneStyle = {
    aspectRatio:aspectRatio
  };

  return (
    <div className="phone mx-auto" ref={phoneDivRef} style={phoneStyle}>

      <div className="buttons">
        <div className="left">
          <div className="button"></div>
          <div className="button"></div>
          <div className="button"></div>
        </div>
        <div className="right">
          <div className="button"></div>
        </div>
      </div>
      <div className="camera"></div>
      <div className="screen-container">
        <div className="bg">
          <b>
            <h1>DESIGN PROCESS</h1>
          </b>
          <div className="row p-3 mobile-screen">
            <div className="p-4 my-4 glow-div mobile-content-div">
              UX AUDIT & COMPETIDORS ANALYSIS
            </div>
            <div
              style={{ marginLeft: "140px" }}
              className="prototyping p-4 my-4 glow-div mobile-content-div"
            >
              PROTOTYPING
            </div>
            <div className="p-4 my-4 mx-auto glow-div mobile-content-div">
              DESING CONCEPTS & UI DESING
            </div>
            <div className="p-4 my-4 mx-auto glow-div mobile-content-div">ANIMATION</div>
            <div className="p-4 my-4 ms-auto glow-div mobile-content-div">
              WEB FLOW DEVELOPMENT
            </div>
          </div>
          <div className="row">
            <div className="p-4 col-md-7  my-4  zoom-div mobile-content-div-lg-1">
              <h4>Empathy</h4>
              In the Empathy phase, we immerse ourselves in the world of our
              users to understand their needs and desires. We use tools such as
              interviews, observations, and surveys to gain a deep understanding
              of their problems and challenges.
            </div>
            <div className="p-4 col-md-7  my-4 ms-auto  zoom-div mobile-content-div-lg-2">
              <h4>Definition</h4>
              In the Definition phase, we synthesize the information collected
              during the Empathy phase to clearly and precisely define the
              problem. We use grouping techniques, mapping, and trend analysis
              to find patterns and design opportunities.
            </div>
            <div className="p-4 col-md-7  my-4  zoom-div mobile-content-div-lg-1">
              <h4>Solution</h4>
              Lastly, in the Solution phase, we use creativity and
              experimentation to develop innovative and effective solutions. We
              work closely with our clients and users to prototype and test
              solutions quickly, allowing us to iterate and continuously
              improve.
            </div>
            <div className="p-4 col-md-7  my-4 ms-auto  zoom-div mobile-content-div-lg-2">
              In each phase of the Design Thinking process, our team of design
              and strategy experts works closely with our clients to ensure that
              the best possible outcomes are achieved. We are committed to
              user-centered design and believe that this approach is essential
              to the success of any project.
            </div>
          </div>
        </div>

        <div className="notch-container" tabindex="0">
          <div className="notch">

          </div>
        </div>
        <div className="notch-blur"></div>
      </div>
    </div>
  );
}
