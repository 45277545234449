import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top"
        style={{ backgroundImage: `url(/Images/home.png)`, }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container bg-image-top-container justify-content-start d-flex align-items-center flex-wrap"
        >

          <div className="col-lg-6 col-md-8 p-5 rounded-5 digital-marketing-div">
            <b>
              <h1>
                Digital Marketing
                <br />
                SEO Marketing
              </h1>
            </b>
            <p className="justify">
              Contact us today to learn more about our Digital Marketing and SEO
              services. Our team of experts is ready to help you develop a
              customized strategy that is tailored to your unique needs and
              goals. Let's work together to build your brand and reach your
              target audience online. Contact us now to get started!
            </p>
            <div className="col-12 align-items-center d-flex justify-content-end">
              <a href="#digital-marketing" className="me-2  btn bg-light rounded-5 my-3 ">
                <b className="primary-color">Digital Marketing</b>
              </a>
              <a href="#seo" className="btn ms-2 bg-light rounded-5 my-3 ">
                <b className="primary-color ms-2">SEO</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
