import React from "react";

export default function DigitalMarketing() {
  return (
    <>
      <div id="digital-marketing" className=" container-fluid ">
        <div className="container d-flex flex-column justify-contgent-center">
          <div className="row  mx-auto contact-us-div center-div">
            <div className="col-11 text-center mx-auto">
              <h1>
                Are you ready to take your digital marketing to the next level?
              </h1>
              <p className="col-sm-9 mx-auto text-center">
                Our team of experts is here to help you build your online
                presence and reach your target audience through a range of
                digital marketing services.
              </p>
              <a href="#contact" className="btn btn-contact-us">
                <b>Contact Us</b>
              </a>
            </div>
          </div>

          <div id="digital-marketing" className="row my-5">
            <div className="col-12">
              <div
                className="col-12 rounded-5 p-5 justify marketing-bg-img"
                style={{
                  backgroundImage: "url(Images/market-analysis.jpg)",
                }}
              >
                <div className="row my-4 markiting-row">
                  <h1 className="heading-marketing w-100 text-end">
                    <b>Digital Marketing</b>
                  </h1>
                  <div className="col-lg-4 col-md-5 col-sm-10">
                    <div className="col-12 rounded-5 p-4 justify zoom-div  overlay-div-marketing">
                      At our company, we pride ourselves on offering a
                      comprehensive service that is tailored to meet the
                      specific needs of our clients. Our service is based on
                      providing dedicated and ad-hoc support to guide and train
                      our clients in the use of the most appropriate technology
                      and solutions for their type of business and volume of
                      activity.
                    </div>
                  </div>
                </div>
                <div className="row my-4 markiting-row">
                  <div className="col-lg-4 col-md-5 col-sm-10">
                    <div className="col-12 rounded-5 p-4 justify zoom-div  overlay-div-marketing">
                      Our team of digital marketing experts will work closely
                      with you to develop a customized strategy that is tailored
                      to your business goals and target audience. We offer a
                      range of services, including social media management,
                      content creation, paid advertising, and more.
                    </div>
                  </div>
                </div>
                <div className="row markiting-row bottom-over-lay-div d-flex justify-content-end align-items-stretch my-4 me-0">
                  <div className="col-lg-4 col-md-5 col-sm-10    my-2">
                    <div
                      className="col-12 mx-auto rounded-5 p-4  zoom-div justify overlay-div-marketing "
                      style={{ height: "100%" }}
                    >
                      One of our core services is web design and development. We
                      work with our clients to design and program websites,
                      ecommerce platforms and marketplaces that are tailored to
                      their unique needs and goals. We choose the technology
                      that best suits each project, ensuring the best possible
                      impact for our clients business in terms of user
                      experience.
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-sm-10 my-2 ">
                    <div
                      className="col-12 mx-auto rounded-5 p-4  zoom-div justify  overlay-div-marketing "
                      style={{ height: "100%" }}
                    >
                      In addition to web design, we also offer digital marketing
                      services to help our clients build their brand and grow
                      their online presence. Our social media team can help you
                      build a strong presence on social networks, taking care of
                      your brand and your content to increase your community
                      with the message that best suits your strategy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
